.ConversationBubble__convo_card {
  position: relative;
}

.small {
  margin: 20px 20px;
  width: 275px;
  height: 275px;
  background: linear-gradient(360deg, white, darkkhaki);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  text-align: center;
  cursor: pointer;
}
.ConersationBubble__message_select {
  background-color: rgb(255, 255, 255);
  color: rgb(15, 55, 202);
  border-left: none;
  border-right: none;
  width: 100%;
  margin-top: -4px;
  margin-bottom: 9px;
  border-radius: 5px;
}

.disabled {
  background-color: rgba(221, 221, 221, 0.904);
}

.ConversationBubble__message_desc {
  font-weight: 600;
}

.expanded {
  display: flex;
  flex-direction: column;
  min-height: 55vh;
  width: 98vw;
  max-width: 1240px;
  background-color: rgba(255, 255, 255, 1);
  position: absolute;
  border-radius: 5px;
  border: 1px solid black;
  transition: all 0.25s cubic-bezier(0, 0.56, 0.69, 0.49); /*make it smooth*/
  z-index: 3;
}
.conversationBubble__message_wrapper {
  position: relative;
  display: flex;
  flex-direction: row;
  height: 64vh;
}
.ConversationBubble__messages_container {
  display: flex;
  padding: 5px;
  flex: 1;
  background-color: rgb(15, 55, 202, 0.8);
  flex-direction: column;
  max-height: 64vh;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.ConversationBubble__messages_container::-webkit-scrollbar {
  display: none;
}

.ConversationBubble__nav {
  text-align: left;
  padding: 5px;
  background-color: rgb(15, 55, 202, 0.8);
}

.ConversationBubble__nav button {
  background-color: white;
  border: 1px solid white;
  border-radius: 5px;
  margin-right: 10px;
  margin-top: 0px;
  color: rgb(15, 55, 202);
  font-weight: 600;
  cursor: pointer;
}

.ConversationBubble__pal_icon {
  font-size: 40px;
}

.ConversationBubble__content {
  display: flex;
  flex-direction: column;
  max-height: 70vh;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  background-color: white;
  flex: 3;
}

.ConversationBubble__content::-webkit-scrollbar {
  display: none;
}

.transparent {
  background: none;
}

@media all and (max-width: 500px) {
  .expanded {
    min-height: calc(100vh - 116px);
    top: 0;
  }
  .conversationBubble__message_wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .transparent {
    display: none;
  }
  .ConversationBubble__messages_container {
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 116px - 31px);
  }
  .ConversationBubble__content {
    width: 98%;
    height: 100%;
    padding: 6px;
    margin: 0 2px;
    border: 1px solid black;
    border-radius: 5px;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
  }
  .ConersationBubble__message_select {
    width: 100%;
  }
}
