@import url('https://fonts.googleapis.com/css2?family=Romanesco&family=The+Girl+Next+Door&display=swap');
header {
  background-color: rgba(255, 255, 255, 0.5);
  align-items: center;
  padding:0;
  display: flex;
  justify-content: center;
}

#header_container {
  display: flex;
  width: 90vw;
  min-width: 250px;
  max-width: 1240px;
  align-items: center;
  justify-content: space-between;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.header_title, .header_image {
  display: flex;
}

img.quill {
  width: auto;
  max-height: 8vh;
  min-width: 30px;
}

.header_title h1 a {
  max-height: 8vw;
  font-size: 6vh;
  font-family: 'Romanesco', cursive;
  color: black;
  text-decoration: none;
  align-self: center;
}

#nav_no_token, #nav_logged_in {
  display: flex;
  align-items: flex-end;
}

#nav_no_token a:first-child, #nav_logged_in a:first-child {
  padding: 0 1vh 0 0;
}

nav a {
  justify-content: space-between;
  font-family: 'The Girl Next Door', cursive;
  text-decoration: none;
  font-size: 3vh;
  color: black;
}

nav a:hover {
  color: #9c27ca;
}

