.Loading {
  position: fixed;
  width: 100%;
}

.Loading__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  margin: 0 auto;
  background-color:  rgba(255, 255, 255, 0.95);
  width: 80vw;
  max-width: 750px;
  padding: 20px;
  border-radius: 5px;
  height: 30vh;
}

.Loading > p {
  font-size: 22px;
  font-weight: 800;

}

.Loading__spinner {
  font-size: 60px;
  color: rgb(15, 55, 202);
}