/* Bring in normalize.css styles */
@import-normalize;
@import url('https://fonts.googleapis.com/css2?family=Amiri&family=Patrick+Hand&family=Satisfy&display=swap');
*{
  box-sizing:border-box;
}

#root {
  height: 100vh;
}

body {
  height: 100vh;
  margin:0;
  padding:0;
  background: url('./images/background.jpg') no-repeat center center fixed;
  background-size: cover;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.required,
.error {
  color: red;
}
