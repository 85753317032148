footer {
  background-color: rgba(255, 255, 255, 0.5);
  padding: 4px 2px 2px 2px;
}

.Footer_Nav_Container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 4px 2px 2px 2px;
}

.Footer_Nav_Container a {
  font-family: 'The Girl Next Door', cursive;
}
 