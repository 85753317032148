.App {
  display: grid;
  min-height: 100vh;
  grid-template-rows: 10vh auto minmax(auto, 6vh);
}

.App_main {
  height: auto;
  align-content: center;
  text-align: center;
}

@media all and (max-width: 360px) {
  .App{
    width: auto;
    grid-template-rows: 10vh auto 15vh;
  }

  nav, nav div{
    flex-direction: column;
    justify-content: space-evenly;
  }
}