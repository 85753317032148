.dashboard {
  max-width: 1240px;
  margin: 0 auto;
}

.welcome_text {
  margin: 10px 4px;
  color: black;
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 22px;
}
.Active_Conversations {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-content: center;
}

.Dashboard__newPal_button {
  margin: 20px 20px;
  width: 275px;
  height: 275px;
  background-image: linear-gradient(360deg, white, darkkhaki);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  text-align: center;
  cursor: pointer;
}
