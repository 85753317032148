.about_container {
  margin: 20px 20px;
  width: auto;
  padding: 10px;
  background-image: linear-gradient(360deg, white, darkkhaki);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

}
.about_container ul{
  text-align: left;
  margin: auto;
  width: 360px;
  list-style-type: square ;
  padding-bottom: 6px;
}

.about_container a{
  text-decoration-line: underline;
  text-decoration-color: rgb(15,55,202);  
  color: black;
}