.Message_Form {
  margin-top: 15px;
  padding: 5px;
}

.Message__header {
  margin-bottom: 20px;
}

.Message__textarea {
  width: 100%;
  max-width: 400px;
  min-height: 100px;
}