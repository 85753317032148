@import url(https://fonts.googleapis.com/css2?family=Amiri&family=Patrick+Hand&family=Satisfy&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Romanesco&family=The+Girl+Next+Door&display=swap);
/* Bring in normalize.css styles */
/* Document
 * ========================================================================== */
/**
 * 1. Correct the line height in all browsers.
 * 2. Prevent adjustments of font size after orientation changes in
 *    IE on Windows Phone and in iOS.
 */
html {
  line-height: 1.15; /* 1 */
  -ms-text-size-adjust: 100%; /* 2 */
  -webkit-text-size-adjust: 100%; /* 2 */
}
/* Sections
 * ========================================================================== */
/**
 * Correct the font size and margin on `h1` elements within `section` and
 * `article` contexts in Chrome, Edge, Firefox, and Safari.
 */
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}
/* Grouping content
 * ========================================================================== */
/**
 * Remove the margin on nested lists in Chrome, Edge, IE, and Safari.
 */
dl dl,
dl ol,
dl ul,
ol dl,
ul dl {
  margin: 0;
}
/**
 * Remove the margin on nested lists in Edge 18- and IE.
 */
ol ol,
ol ul,
ul ol,
ul ul {
  margin: 0;
}
/**
 * 1. Add the correct box sizing in Firefox.
 * 2. Show the overflow in Edge 18- and IE.
 */
hr {
  box-sizing: content-box; /* 1 */
  height: 0; /* 1 */
  overflow: visible; /* 2 */
}
/**
 * Add the correct display in IE.
 */
main {
  display: block;
}
/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
pre {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}
/* Text-level semantics
 * ========================================================================== */
/**
 * Add the correct text decoration in Edge 18-, IE, and Safari.
 */
abbr[title] {
  text-decoration: underline;
  text-decoration: underline dotted;
}
/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */
b,
strong {
  font-weight: bolder;
}
/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
code,
kbd,
samp {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}
/**
 * Add the correct font size in all browsers.
 */
small {
  font-size: 80%;
}
/* Embedded content
 * ========================================================================== */
/**
 * Hide the overflow in IE.
 */
svg:not(:root) {
  overflow: hidden;
}
/* Forms
 * ========================================================================== */
/**
 * Remove the margin on controls in Safari.
 */
button,
input,
select {
  margin: 0;
}
/**
 * 1. Show the overflow in IE.
 * 2. Remove the inheritance of text transform in Edge 18-, Firefox, and IE.
 */
button {
  overflow: visible; /* 1 */
  text-transform: none; /* 2 */
}
/**
 * Correct the inability to style buttons in iOS and Safari.
 */
button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}
/**
 * Correct the padding in Firefox.
 */
fieldset {
  padding: 0.35em 0.75em 0.625em;
}
/**
 * Show the overflow in Edge 18- and IE.
 */
input {
  overflow: visible;
}
/**
 * 1. Correct the text wrapping in Edge 18- and IE.
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 */
legend {
  box-sizing: border-box; /* 1 */
  color: inherit; /* 2 */
  display: table; /* 1 */
  max-width: 100%; /* 1 */
  white-space: normal; /* 1 */
}
/**
 * 1. Add the correct display in Edge 18- and IE.
 * 2. Add the correct vertical alignment in Chrome, Edge, and Firefox.
 */
progress {
  display: inline-block; /* 1 */
  vertical-align: baseline; /* 2 */
}
/**
 * Remove the inheritance of text transform in Firefox.
 */
select {
  text-transform: none;
}
/**
 * 1. Remove the margin in Firefox and Safari.
 * 2. Remove the default vertical scrollbar in IE.
 */
textarea {
  margin: 0; /* 1 */
  overflow: auto; /* 2 */
}
/**
 * 1. Correct the odd appearance in Chrome, Edge, and Safari.
 * 2. Correct the outline style in Safari.
 */
[type="search"] {
  -webkit-appearance: textfield; /* 1 */
  outline-offset: -2px; /* 2 */
}
/**
 * Correct the cursor style of increment and decrement buttons in Safari.
 */
::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto;
}
/**
 * Correct the text style of placeholders in Chrome, Edge, and Safari.
 */
::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.54;
}
/**
 * Remove the inner padding in Chrome, Edge, and Safari on macOS.
 */
::-webkit-search-decoration {
  -webkit-appearance: none;
}
/**
 * 1. Correct the inability to style upload buttons in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */
::-webkit-file-upload-button {
  -webkit-appearance: button; /* 1 */
  font: inherit; /* 2 */
}
/**
 * Remove the inner border and padding of focus outlines in Firefox.
 */
::-moz-focus-inner {
  border-style: none;
  padding: 0;
}
/**
 * Restore the focus outline styles unset by the previous rule in Firefox.
 */
:-moz-focusring {
  outline: 1px dotted ButtonText;
}
/**
 * Remove the additional :invalid styles in Firefox.
 */
:-moz-ui-invalid {
  box-shadow: none;
}
/* Interactive
 * ========================================================================== */
/*
 * Add the correct display in Edge 18- and IE.
 */
details {
  display: block;
}
/*
 * Add the correct styles in Edge 18-, IE, and Safari.
 */
dialog {
  background-color: white;
  border: solid;
  color: black;
  display: block;
  height: -moz-fit-content;
  height: -webkit-fit-content;
  height: fit-content;
  left: 0;
  margin: auto;
  padding: 1em;
  position: absolute;
  right: 0;
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content;
}
dialog:not([open]) {
  display: none;
}
/*
 * Add the correct display in all browsers.
 */
summary {
  display: list-item;
}
/* Scripting
 * ========================================================================== */
/**
 * Add the correct display in IE.
 */
template {
  display: none;
}
/* User interaction
 * ========================================================================== */
*{
  box-sizing:border-box;
}

#root {
  height: 100vh;
}

body {
  height: 100vh;
  margin:0;
  padding:0;
  background: url(/static/media/background.f36d8e7b.jpg) no-repeat center center fixed;
  background-size: cover;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.required,
.error {
  color: red;
}

header {
  background-color: rgba(255, 255, 255, 0.5);
  align-items: center;
  padding:0;
  display: flex;
  justify-content: center;
}

#header_container {
  display: flex;
  width: 90vw;
  min-width: 250px;
  max-width: 1240px;
  align-items: center;
  justify-content: space-between;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.header_title, .header_image {
  display: flex;
}

img.quill {
  width: auto;
  max-height: 8vh;
  min-width: 30px;
}

.header_title h1 a {
  max-height: 8vw;
  font-size: 6vh;
  font-family: 'Romanesco', cursive;
  color: black;
  text-decoration: none;
  align-self: center;
}

#nav_no_token, #nav_logged_in {
  display: flex;
  align-items: flex-end;
}

#nav_no_token a:first-child, #nav_logged_in a:first-child {
  padding: 0 1vh 0 0;
}

nav a {
  justify-content: space-between;
  font-family: 'The Girl Next Door', cursive;
  text-decoration: none;
  font-size: 3vh;
  color: black;
}

nav a:hover {
  color: #9c27ca;
}


textarea {
  font-family: inherit;
}

button {
  font-family: inherit;
}

input {
  display: block;
  width: 100%;
  max-width: 400px;
  border-radius: 5px;
  margin:  15px auto;
  padding: 5px;
  border: 1px solid black;
}

legend {
  align-self: center;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 25px 15px;
  width: 90vw;
  max-width: 500px;
  background-image: linear-gradient(360deg, white, darkkhaki);
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19) ;
}

.form_button {
  display: block;
  width: 100%;
  max-width: 400px;
  padding: 9px 5px;
  margin: 15px auto 15px auto;
  border: 2px solid rgb(15,55,202);
  border-radius: 5px;
  font-weight: 700;
  letter-spacing: 1.5px;
  background-color: rgb(15, 55, 202);
  color: white;
}

.form_button:hover {
  opacity: 0.9;
}

label{
  display: block;
  width: 100%;
  font-weight: 700;
}
.Loading {
  position: fixed;
  width: 100%;
}

.Loading__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  margin: 0 auto;
  background-color:  rgba(255, 255, 255, 0.95);
  width: 80vw;
  max-width: 750px;
  padding: 20px;
  border-radius: 5px;
  height: 30vh;
}

.Loading > p {
  font-size: 22px;
  font-weight: 800;

}

.Loading__spinner {
  font-size: 60px;
  color: rgb(15, 55, 202);
}
.RegistrationForm__link {
  color: black;
  font-weight: 500;
}

.RegistrationForm__required_text {
  margin-top: 0;
  margin-bottom: 25px;
  font-size: 12px;
}
.welcome {
  padding: 0 10px;
}

.welcome p {
  font-size: 18px;
}

.about_zippal {
  margin: 20px 20px;
  width: auto;
  padding: 10px;
  background-image: linear-gradient(360deg, white, darkkhaki);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

}
hr {
  border: 1px solid rgb(15,55,202,0.4);
  width:100px;
}
.find_new_pal {
  border: 1px solid black;
  margin: 17px auto;
  background-image: linear-gradient(360deg, white, darkkhaki);
  padding: 15px;
  width: 85vw;
  max-width: 800px;
}

.FindNewPal__icon {
  font-size: 40px;
}

.Message_Form {
  margin-top: 15px;
  padding: 5px;
}

.Message__header {
  margin-bottom: 20px;
}

.Message__textarea {
  width: 100%;
  max-width: 400px;
  min-height: 100px;
}
.ConversationNotification__icon {
  font-size: 20px;
  color: white;
}

.ConversationNotification__icon_container {
  position: absolute;
  z-index: 2;
  top: -20px;
  right: -20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid white;
  z-index: 2;
}

.red {
  background-color: red;
}

.blue {
  background-color: #0D37CA;
}
.ConversationBubble__convo_card {
  position: relative;
}

.small {
  margin: 20px 20px;
  width: 275px;
  height: 275px;
  background: linear-gradient(360deg, white, darkkhaki);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  text-align: center;
  cursor: pointer;
}
.ConersationBubble__message_select {
  background-color: rgb(255, 255, 255);
  color: rgb(15, 55, 202);
  border-left: none;
  border-right: none;
  width: 100%;
  margin-top: -4px;
  margin-bottom: 9px;
  border-radius: 5px;
}

.disabled {
  background-color: rgba(221, 221, 221, 0.904);
}

.ConversationBubble__message_desc {
  font-weight: 600;
}

.expanded {
  display: flex;
  flex-direction: column;
  min-height: 55vh;
  width: 98vw;
  max-width: 1240px;
  background-color: rgba(255, 255, 255, 1);
  position: absolute;
  border-radius: 5px;
  border: 1px solid black;
  transition: all 0.25s cubic-bezier(0, 0.56, 0.69, 0.49); /*make it smooth*/
  z-index: 3;
}
.conversationBubble__message_wrapper {
  position: relative;
  display: flex;
  flex-direction: row;
  height: 64vh;
}
.ConversationBubble__messages_container {
  display: flex;
  padding: 5px;
  flex: 1 1;
  background-color: rgb(15, 55, 202, 0.8);
  flex-direction: column;
  max-height: 64vh;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.ConversationBubble__messages_container::-webkit-scrollbar {
  display: none;
}

.ConversationBubble__nav {
  text-align: left;
  padding: 5px;
  background-color: rgb(15, 55, 202, 0.8);
}

.ConversationBubble__nav button {
  background-color: white;
  border: 1px solid white;
  border-radius: 5px;
  margin-right: 10px;
  margin-top: 0px;
  color: rgb(15, 55, 202);
  font-weight: 600;
  cursor: pointer;
}

.ConversationBubble__pal_icon {
  font-size: 40px;
}

.ConversationBubble__content {
  display: flex;
  flex-direction: column;
  max-height: 70vh;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  background-color: white;
  flex: 3 1;
}

.ConversationBubble__content::-webkit-scrollbar {
  display: none;
}

.transparent {
  background: none;
}

@media all and (max-width: 500px) {
  .expanded {
    min-height: calc(100vh - 116px);
    top: 0;
  }
  .conversationBubble__message_wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .transparent {
    display: none;
  }
  .ConversationBubble__messages_container {
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 116px - 31px);
  }
  .ConversationBubble__content {
    width: 98%;
    height: 100%;
    padding: 6px;
    margin: 0 2px;
    border: 1px solid black;
    border-radius: 5px;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
  }
  .ConersationBubble__message_select {
    width: 100%;
  }
}

.NewConvoMessage__container {
  border: 1px solid black;
  width: 85vw;
  max-width: 800px;
  margin: 0 auto;
  background: linear-gradient(360deg, white, darkkhaki);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.dashboard {
  max-width: 1240px;
  margin: 0 auto;
}

.welcome_text {
  margin: 10px 4px;
  color: black;
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 22px;
}
.Active_Conversations {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-content: center;
}

.Dashboard__newPal_button {
  margin: 20px 20px;
  width: 275px;
  height: 275px;
  background-image: linear-gradient(360deg, white, darkkhaki);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  text-align: center;
  cursor: pointer;
}

.ProfileForm {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.ProfileForm__form {
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: 0 auto;
  background-image: linear-gradient(190deg, white, darkkhaki);
}

.ProfileForm form input {
  margin: 0 auto;
  margin-top: 10px;
  width: 50%;
  max-width: 400px;
}

.ProfileForm__about {
  margin: 0 auto;
  width: 100%;
}

.about {
  margin: 0 auto;
  height: 7rem;
  width: 90%;
  max-width: 800px;
  background-color: rgb(238, 237, 220, 0.7);
  padding: 10px;
}

label {
  margin-bottom: 4px;
}

.ProfileForm button {
  margin-top: 20px;
}

.IconSelect {
  display: block;
  margin: 20px auto;
  background-color: #cbc68c;
  width: 200px;
  height: 200px;
  text-align: left;
  overflow-y: scroll;
}

.IconSelect button {
  font-size: 30px;
  margin: 0;
  padding: 5px;
  width: 32%;
  background: transparent;
  box-shadow: 0px transparent;
  border: 0px solid transparent;
  box-shadow: 0px 0px 0px transparent;
  text-shadow: 0px 0px 0px transparent;
}

.IconSelect button:hover {
  background: black;
  box-shadow: 0px 0px 0px transparent;
  border: 0px solid transparent;
  text-shadow: 0px 0px 0px transparent;
  color: greenyellow;
}

.IconSelect button:active {
  outline: none;
  border: none;
}

.IconSelect button:focus {
  outline: 0;
  background: black;
  color: greenyellow;
}

.hidden_button_text {
  display: none;
}

.ProfileCard_main {
  border: 1px solid bisque;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 900px;
  width: 90vw;
  margin: 0 auto 25px auto;
  padding: 12px;
  background: linear-gradient(220deg, white, darkkhaki);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.ProfileCard__icon_container {
  margin: auto 0;
}

.ProfileCard__icon_container > p {
  font-size: 20px;
  font-weight: 600;
}

.ProfileCard__bio_location {
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: left;
  justify-content: space-evenly;
}

.ProfileCard__label {
  display:block;
  margin: 10px 0;
  font-size: 18px;
  font-weight: 600;
  color: #3a392a;
  border-bottom: 1px solid black;
}

.ProfileCard__data {
  margin: 0 0 15px 0;
  font-size: 16px;
}

.about_container {
  margin: 20px 20px;
  width: auto;
  padding: 10px;
  background-image: linear-gradient(360deg, white, darkkhaki);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

}
.about_container ul{
  text-align: left;
  margin: auto;
  width: 360px;
  list-style-type: square ;
  padding-bottom: 6px;
}

.about_container a{
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  -webkit-text-decoration-color: rgb(15,55,202);
          text-decoration-color: rgb(15,55,202);  
  color: black;
}
.contact_card_box {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.contact_card {
  width: 15vw;
  background-color: burlywood;
  min-width: 200px;
}
.ContactDetails {
  width: 70%;
  margin: 0 auto;
  padding-bottom: 20px;
  margin-bottom: 70px;
  background-image: linear-gradient(360deg, white, darkkhaki);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.ContactDetails h4 {
  font-size: 28px;
}

.ContactDetails span {
  margin-left: 10px;
}

.ContactDetails a {
  text-decoration: none;
  color: black;
  font-size: 24px;
  margin: 10px;
  padding: 10px;
  border-radius: 10px;
}

.ContactDetails a:hover {
  color: blue;
  background-color: darkkhaki;
}

.contact_img {
  width: 150px;
  margin: 0 auto;
}

.contact_img img {
  width: 100%;
  border-radius: 50%;
}

.personal_bio {
  padding: 0 40px;
}

.contact_card_box button {
  margin: 0;
  padding: 5px;
  width: 22%;
  background-color: darkkhaki;
  box-shadow: 0px transparent;
  border: 1px solid #6c683e;
  box-shadow: 0px 0px 0px transparent;
  text-shadow: 0px 0px 0px transparent;
}

.contact_card_box button:hover {
  background: #6c683e;
  box-shadow: 0px 0px 0px transparent;
  border: 0px solid transparent;
  text-shadow: 0px 0px 0px transparent;
  color: #d6d8e3;
  cursor: pointer;
}

.contact_card_box button:active {
  outline: none;
  border: none;
}

.contact_card_box button:focus {
  outline: 0;
  background: #beb442;
  color: #223187;
}

#terms, #privacy {
  margin: 2vw auto;
  padding: 1vh 3vw;
  border: 1px solid bisque;
  background-color: rgba(135, 97, 32, 0.25);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

#Policy_Container{
  max-width: 1240px;
  width: 80vw;
  margin: 0 auto;
}

#terms p, #privacy p {
    text-align: justify;
}

.App {
  display: grid;
  min-height: 100vh;
  grid-template-rows: 10vh auto minmax(auto, 6vh);
}

.App_main {
  height: auto;
  align-content: center;
  text-align: center;
}

@media all and (max-width: 360px) {
  .App{
    width: auto;
    grid-template-rows: 10vh auto 15vh;
  }

  nav, nav div{
    flex-direction: column;
    justify-content: space-evenly;
  }
}
footer {
  background-color: rgba(255, 255, 255, 0.5);
  padding: 4px 2px 2px 2px;
}

.Footer_Nav_Container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 4px 2px 2px 2px;
}

.Footer_Nav_Container a {
  font-family: 'The Girl Next Door', cursive;
}
 
