.welcome {
  padding: 0 10px;
}

.welcome p {
  font-size: 18px;
}

.about_zippal {
  margin: 20px 20px;
  width: auto;
  padding: 10px;
  background-image: linear-gradient(360deg, white, darkkhaki);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

}
hr {
  border: 1px solid rgb(15,55,202,0.4);
  width:100px;
}