.IconSelect {
  display: block;
  margin: 20px auto;
  background-color: #cbc68c;
  width: 200px;
  height: 200px;
  text-align: left;
  overflow-y: scroll;
}

.IconSelect button {
  font-size: 30px;
  margin: 0;
  padding: 5px;
  width: 32%;
  background: transparent;
  box-shadow: 0px transparent;
  border: 0px solid transparent;
  box-shadow: 0px 0px 0px transparent;
  text-shadow: 0px 0px 0px transparent;
}

.IconSelect button:hover {
  background: black;
  box-shadow: 0px 0px 0px transparent;
  border: 0px solid transparent;
  text-shadow: 0px 0px 0px transparent;
  color: greenyellow;
}

.IconSelect button:active {
  outline: none;
  border: none;
}

.IconSelect button:focus {
  outline: 0;
  background: black;
  color: greenyellow;
}

.hidden_button_text {
  display: none;
}
