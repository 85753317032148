.ConversationNotification__icon {
  font-size: 20px;
  color: white;
}

.ConversationNotification__icon_container {
  position: absolute;
  z-index: 2;
  top: -20px;
  right: -20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid white;
  z-index: 2;
}

.red {
  background-color: red;
}

.blue {
  background-color: #0D37CA;
}